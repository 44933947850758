<template>
    <v-card class="mt-2 pa-1 transaction font-weight-bold">
        <v-card-text>
            <p class="text-2xl font-weight-semibold"
               style="margin-bottom: 0;color: #016FD0;">
                <span v-if="selectedLanguage == 'ar'">{{ card.cardNameAr }}</span>
                <span v-if="selectedLanguage == 'en'">{{ card.cardName }}</span>
            </p>
            <div class="text-1xl font-weight-semibold pt-2">
                {{ this.serialNo }}
            </div>
        </v-card-text>

        <v-card-text>
            <v-card>
                <v-card-text class="pa-3">
                    <v-row>
                        <v-col cols="12"
                               lg="3"
                               md="6"
                               sm="12">
                            <div class="d-flex flex-column text-center">
                                <div class="text-blue font-weight-semibold">
                                    <span class="d-flex justify-center"
                                          style="font-size: 12px;">
                                        {{ $t('Amount Available') }}
                                        <span>
                                            <v-tooltip color="primary"
                                                       top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-img v-bind="attrs"
                                                           v-on="on"
                                                           contain
                                                           style="height: 16px;"
                                                           class="auth-3d-group overflow-hidden image"
                                                           :src="require(`@/assets/images/icons/iota-circle.png`)">
                                                    </v-img>
                                                </template>
                                                <div style="max-width: 200px;padding: 6px;">
                                                    <span>{{ $t(`CurrentBalanceInfo`)
                                                    }}</span>
                                                </div>
                                            </v-tooltip>

                                        </span>
                                    </span>

                                </div>
                                <div style="font-size: 16px;"
                                     class="text-color-black font-weight-semibold text-1xl">
                                    <span v-if="selectedLanguage == 'en'">
                                        {{ $t(cardDetails.currentBalance.cur) }}
                                    </span>
                                    {{ cardDetails.currentBalance.amt | VMask(currencyMask) }}
                                    <span v-if="selectedLanguage == 'ar'">
                                        {{ $t(cardDetails.currentBalance.cur) }}
                                    </span>
                                    <span v-if="cardDetails.currentBalance.cr">CR</span>
                                </div>
                            </div>
                        </v-col>
                        <v-divider class="my-2 d-lg-flex d-none"
                                   :vertical="true"></v-divider>
                        <v-col cols="12"
                               lg="3"
                               md="6"
                               sm="12">
                            <div class="d-flex flex-column text-center">
                                <div class="text-blue font-weight-semibold">
                                    <span class="d-flex justify-center"
                                          style="font-size: 12px;">
                                        {{ $t('Available Credit') }}
                                        <span>
                                            <v-tooltip color="primary"
                                                       top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-img v-bind="attrs"
                                                           v-on="on"
                                                           contain
                                                           style="height: 16px;"
                                                           class="auth-3d-group overflow-hidden image"
                                                           :src="require(`@/assets/images/icons/iota-circle.png`)">
                                                    </v-img>
                                                </template>
                                                <div style="max-width: 200px;padding: 6px;">
                                                    <span>{{ $t(`AvailableCreditInfo`)
                                                    }}</span>
                                                </div>
                                            </v-tooltip>
                                        </span>
                                    </span>
                                </div>
                                <div style="font-size: 16px;"
                                     class="text-color-black font-weight-semibold text-1xl">
                                    <span
                                          v-if="selectedLanguage == 'en' && cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                        {{ $t(cardDetails.availableCredit.cur) }}
                                    </span>
                                    <span v-if="cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                        {{ cardDetails.availableCredit.amt | VMask(currencyMask) }}
                                    </span>
                                    <span v-if="cardDetails.availableCredit.amt == 'No Pre-Set Limit'">
                                        {{ $t(cardDetails.availableCredit.amt) }}
                                    </span>
                                    <span
                                          v-if="selectedLanguage == 'ar' && cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                        {{ $t(cardDetails.availableCredit.cur) }}
                                    </span>
                                </div>
                            </div>
                        </v-col>
                        <v-divider class="my-2 d-lg-flex d-none"
                                   :vertical="true"></v-divider>
                        <v-col cols="12"
                               lg="3"
                               md="6"
                               sm="12">
                            <div class="d-flex flex-column text-center">
                                <div class="text-blue font-weight-semibold">
                                    <span class="d-flex justify-center"
                                          style="font-size: 12px;">
                                        {{ $t('Previous Balance') }}
                                        <span>
                                            <v-tooltip color="primary"
                                                       top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-img v-bind="attrs"
                                                           v-on="on"
                                                           contain
                                                           style="height: 16px;"
                                                           class="auth-3d-group overflow-hidden image"
                                                           :src="require(`@/assets/images/icons/iota-circle.png`)">
                                                    </v-img>
                                                </template>
                                                <div style="max-width: 200px;padding: 6px;">
                                                    <span>{{ $t(`PreviousBalanceInfo`)
                                                    }}</span>
                                                </div>
                                            </v-tooltip>
                                        </span>
                                    </span>

                                </div>
                                <div style="font-size: 16px;"
                                     class="text-color-black font-weight-semibold text-1xl">
                                    <span v-if="selectedLanguage == 'en'">
                                        {{ $t(cardDetails.previousBalance.cur) }}
                                    </span>
                                    {{ cardDetails.previousBalance.amt | VMask(currencyMask) }}
                                    <span v-if="selectedLanguage == 'ar'">
                                        {{ $t(cardDetails.previousBalance.cur) }}
                                    </span>
                                    <span v-if="cardDetails.previousBalance.cr">CR</span>
                                </div>
                            </div>
                        </v-col>
                        <v-divider class="my-2 d-lg-flex d-none"
                                   :vertical="true"></v-divider>
                        <v-col cols="12"
                               lg="3"
                               md="6"
                               sm="12">
                            <div class="d-flex flex-column text-center">
                                <div class="text-blue font-weight-semibold">
                                    <span class="d-flex justify-center"
                                          style="font-size: 12px;">
                                        {{ $t('Amount Credit Limit') }}
                                        <span v-if="cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                            <v-tooltip color="primary"
                                                       top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-img v-bind="attrs"
                                                           v-on="on"
                                                           contain
                                                           style="height: 16px;"
                                                           class="auth-3d-group overflow-hidden image"
                                                           :src="require(`@/assets/images/icons/iota-circle.png`)">
                                                    </v-img>
                                                </template>
                                                <div style="max-width: 200px;padding: 6px;">
                                                    <span>
                                                        {{ $t(`CreditLimitInfo`)}}
                                                    </span>
                                                </div>
                                            </v-tooltip>
                                        </span>
                                    </span>

                                </div>
                                <div style="font-size: 16px;"
                                     class="text-color-black font-weight-semibold text-1xl">
                                    <!-- <span v-if="selectedLanguage == 'en'">
                                        {{ $t(cardDetails.creditLimit.cur) }}
                                    </span>
                                    <span v-if="cardDetails.availableCredit.amt == 'No Pre-Set Limit'">
                                        {{ $t(cardDetails.availableCredit.amt) }}
                                    </span>
                                    <span v-if="cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                        {{ cardDetails.creditLimit.amt | VMask(currencyMask) }}
                                    </span>
                                    <span v-if="selectedLanguage == 'ar'">
                                        {{ $t(cardDetails.creditLimit.cur) }}
                                    </span> -->

                                    <span
                                          v-if="selectedLanguage == 'en' && cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                        {{ $t(cardDetails.creditLimit.cur) }}
                                    </span>
                                    <span v-if="cardDetails.availableCredit.amt == 'No Pre-Set Limit'">
                                        {{ $t(cardDetails.availableCredit.amt) }}
                                    </span>
                                    <span v-if="cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                        {{ cardDetails.creditLimit.amt | VMask(currencyMask) }}
                                    </span>
                                    <span
                                          v-if="selectedLanguage == 'ar' && cardDetails.availableCredit.amt != 'No Pre-Set Limit'">
                                        {{ $t(cardDetails.creditLimit.cur) }}
                                    </span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {
    props: {
        card: {
            default()
            {
                return {}
            },
            type: Object
        },
        selectedLanguage: {
            default: 'en',
            type: String
        }
    },
    data: () => ({
        currencyMask,
        stmtDate: '',
        cardName: '',
        expiry: '',
        amountDue: '',
        serialNo: '',
        cardDetails: {
            currentBalance: {
                cur: 0, amt: 0
            },
            availableCredit: {
                cur: 0, amt: 0
            },
            previousBalance: {
                cur: 0, amt: 0
            },
            creditLimit: {
                cur: 0, amt: 0
            },
        }
    }),
    mounted()
    {
        this.cardDetails = this.card;
        this.expiry = this.card?.expiry;
        this.amountDue = this.card?.amountDue;
        this.serialNo = this.card?.serialNo;
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.v-card {
    box-shadow: none !important;
}

@import '@core/preset/preset/auth.scss';
</style>



