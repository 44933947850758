import store from '@/store';
import moment from 'moment';
import valueWraper from './keyValueMaper';
var transactionUnfiltered;
const group = async (transactionsArray) =>
{
    if (transactionsArray)
    {
        transactionUnfiltered=transactionsArray;
        transactionsArray.forEach(element =>
        {
            element.date = moment(element.date).format('DD-MMM-yy');
            element.postingDate = moment(element.postingDate).format('DD-MMM-yy');
        });
        return await groupingTrans();
    }
    
}
const groupingTrans=async ()=>
{
    let list = transactionUnfiltered
    let dateTransactions = {};
    list.forEach(element =>
    {
        if (!(element.dateSort in dateTransactions))
        {
            dateTransactions[element.dateSort] = [];
        }
        dateTransactions[element.dateSort].push(element);
    });

    let sortable = [];
    for (var key in dateTransactions)
    {
        sortable.push([key, dateTransactions[key]]);
    }

    sortable.sort(function (a, b)
    {
        return a[1] - b[1];
    });

    // sortable.reverse();
    return sortable
}


export default {
    group
}