<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0;">
        <div class="auth-inner main-container-width">
            <v-row v-if="loaded">

                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">

                    <v-card flat
                            class="bg-gradient">
                        <app-header :details="cardDetails"></app-header>

                        <v-row class="px-lg-8 px-xl-8 px-md-8 px-sm-6 px-4 mt-4">
                            <!-- <v-col cols="12"
                                   lg="12">
                                <v-row class="backbtn mt-4 mr-4">
                                    <BackAction :text="$t('Return to Home')"
                                                :output="false" />
                                </v-row>
                            </v-col> -->
                            <v-col cols="12"
                                   lg="3"
                                   sm="12"
                                   md="12">

                                <v-img contain
                                       width="286px"
                                       height="161px"
                                       class="mt-2 auth-3d-group overflow-hidden mx-auto"
                                       :src="cardDetails.image">
                                </v-img>

                                <v-card class="mt-5 mb-6 text-center balance-card-background">
                                    <v-row class="px-5">
                                        <v-col cols="6"
                                               lg="6"
                                               md="6"
                                               sm="6"
                                               class="pa-2 px-1">
                                            <div style="cursor: pointer;"
                                                 @click="goStatements">
                                                <v-img contain
                                                       class="auth-3d-group overflow-hidden statement"
                                                       :src="require(`@/assets/images/icons/statement.png`)">
                                                </v-img>
                                            </div>
                                            <span style="cursor: pointer;"
                                                  @click="goStatements"
                                                  class="text-black en-balance-text ar-balance-text">
                                                {{ $t('statementsTitle') }}
                                            </span>
                                        </v-col>
                                        <v-col cols="6"
                                               lg="6"
                                               md="6"
                                               sm="6"
                                               class="pa-2 px-1">
                                            <div style="cursor: pointer;"
                                                 @click="manageCards">
                                                <v-img contain
                                                       class="auth-3d-group overflow-hidden manage-Card"
                                                       :src="require(`@/assets/images/icons/manage-cards.png`)">
                                                </v-img>
                                            </div>
                                            <span style="cursor: pointer;"
                                                  @click="manageCards"
                                                  class="en-balance-text ar-balance-text text-black">
                                                {{ $t('Manage Card') }}
                                            </span>
                                        </v-col>
                                        <!-- <v-col cols="4"
                                               lg="4"
                                               md="4"
                                               sm="4"
                                               class="pa-2 px-1">
                                            <div style="cursor: pointer;"
                                                 @click="goNotification">
                                                <v-img contain
                                                       class="mr-0 auth-3d-group overflow-hidden notification"
                                                       :src="require(`@/assets/images/icons/notifications.png`)">
                                                </v-img>
                                            </div>
                                            <span style="cursor: pointer;"
                                                  @click="goNotification"
                                                  class="text-black en-balance-text ar-balance-text">
                                                {{ $t('notificationsTitle') }}
                                            </span>
                                        </v-col> -->

                                    </v-row>
                                </v-card>

                                <v-card class="mt-3 balance-card-background"
                                        v-if="payment && this.amountDue.amt > 0">
                                    <v-card-text class="d-flex card-paymentdue">
                                        <div class="payment-due">
                                            <v-img contain
                                                   class="ml-1 auth-3d-group overflow-hidden alert"
                                                   :src="require(`@/assets/images/icons/payment-due.png`)">
                                            </v-img>
                                        </div>

                                        <div class="ml-2">
                                            <span style="font-size: 12px;"
                                                  class="text-black font-weight-semibold">
                                                {{ $t('Payment due:') }}

                                                <span v-if="selectedLanguage == 'en'">
                                                    {{ this.amountDue.cur }}
                                                </span>
                                                {{ this.amountDue.amt | VMask(currencyMask) }}
                                                <span v-if="selectedLanguage == 'ar'">
                                                    {{ this.amountDue.cur }}
                                                </span>
                                            </span>
                                            <p style="font-size: 11px;">
                                                {{ $t('Your Sadad number is') }}: {{ cardDetails.sadadNumber }}
                                            </p>

                                            <div style="font-size: 12px;"
                                                 class="pa-0">
                                                {{ $t('Due date:') }} {{ getArabicDate(cardDetails.nextDueDate,
                                                    selectedLanguage, true) }}
                                            </div>
                                            <div class="text-blue"
                                                 @click="payBils = true"
                                                 style="font-size: 12px;">
                                                {{ $t('How do I pay my bills?') }}
                                            </div>
                                        </div>

                                        <div class="cross">
                                            <v-img @click="payment = false"
                                                   contain
                                                   style="height: 16px;cursor: pointer"
                                                   class="auth-3d-group overflow-hidden mt-0"
                                                   :src="require(`@/assets/images/icons/cross-circle.png`)">
                                            </v-img>
                                        </div>

                                    </v-card-text>
                                </v-card>

                                <v-card class="mt-2 balance-card-background">
                                    <v-card-text class="flex-column text-center">
                                        <v-btn @click="goToSupp"
                                               block
                                               class="text-blue ar-additionalcard en-additionalcard">
                                            {{ $t('Apply for Additional Cards') }}
                                        </v-btn>
                                        <v-btn block
                                               @click="payBils = true"
                                               class="text-blue top-space"
                                               style="border: 1px solid #016FD0; font-size: 11px; border-radius: 5px;">
                                            {{ $t('How to pay my due amount?') }}
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="12"
                                   lg="9"
                                   sm="12"
                                   md="12">

                                <CardDetailTrans :selectedLanguage="selectedLanguage"
                                                 :card="cardDetails"></CardDetailTrans>

                                <v-card v-if="authorizationsLength != 0"
                                        class="mt-7 balance-card-background">
                                    <v-row class="align-center">
                                        <v-col cols="12"
                                               lg="12"
                                               md="12"
                                               sm="12">
                                            <div class="pa-3 d-flex align-center cursor-pointer"
                                                 @click="goToPendingTransaction" >
                                                <div class="pending-img">
                                                    <v-img contain
                                                           class="union auth-3d-group overflow-hidden"
                                                           :src="require(`@/assets/images/Union.png`)">
                                                    </v-img>
                                                </div>
                                                <div class="ml-4">
                                                    <span class="text-black font-weight-semibold"
                                                          style="font-size: 12px;">
                                                        {{ $t('Pending transactions') }}
                                                    </span>
                                                    <div style="font-size: 11px;">
                                                        {{ $t('You have') }}
                                                        {{ authorizationsLength }}
                                                        {{ $t('Pending transactions') }}.
                                                    </div>
                                                </div>
                                                <v-spacer></v-spacer>
                                                <div style="width: 50px;margin-left: 10px;">
                                                    <p class="text-blue font-weight-semibold"
                                                       style="font-size: 12px; cursor: pointer;">
                                                        {{ $t('See all') }}
                                                    </p>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-card class="mt-7 pa-6 balance-card-background">
                                    <div class="d-flex justify-space-between text-black font-weight-semibold align-center">
                                        <p>{{ $t('Recent activity') }}</p>
                                        <p @click="goToTransaction"
                                           class="text-right text-blue font-weight-semibold"
                                           style="font-size: 12px; cursor: pointer;">
                                            {{ $t('See all') }}
                                        </p>
                                    </div>
                                    <div v-if="loaded && transactionsArray.length == 0"
                                         class="text-center text-lg">
                                        {{ $t('No transactions.') }}
                                    </div>
                                    <div v-for="(tran, index) in transactionsArray"
                                         :key="index">
                                        <div style="font-size: 13px;color: #596894;"
                                             class="mt-4 font-weight-semibold">
                                            {{ getArabicDate(tran[0], selectedLanguage, false) }}
                                        </div>
                                        <Transaction :transactionsArray="tran[1]"
                                                     :showBtn="showBtn">
                                        </Transaction>
                                    </div>
                                </v-card>

                                <OffersHome></OffersHome>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="payBils"
                  width="60%"
                  style="overflow: hidden;">
            <v-card class="public-contact">
                <v-card-title>
                    <span class="text-h5 text-color-black"
                          @click="payBils">
                        {{ $t(`How do I pay my bills?`) }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="cursor: pointer"
                          @click="payBils = false"
                          class="arabic-close en-close">
                        <v-icon>
                            {{ icons.mdiClose }}
                        </v-icon>
                    </span>
                </v-card-title>

                <v-card-text>

                    <p style="font-size: 12px;"
                       v-html="getFaqs('pay_bils_ans1')">
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import FAQs from '@/@core/utils/FAQs'
import AppHeader from '../test/AppHeader.vue'
import router from '@/router'
import store from '@/store';
import getRecentActivity from '@/@core/utils/getRecentActivity';
import groupTransactions from '@/@core/utils/groupTransactions';
import Transaction from '../transactions/Transaction.vue';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { mdiClose } from '@mdi/js'
import CardDetailTrans from '../view-transaction/CardDetailTrans.vue';

import BackAction from '@/@core/BackAction.vue';

import arabicDate from '@/@core/utils/arabicDate';

import OffersHome from '../home/OffersHome.vue';

const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});

export default {
    components: {
        AppHeader,
        Transaction,
        CardDetailTrans,
        BackAction,
        OffersHome
    },

    data: () => ({
        currencyMask,
        selectedItem: 1,
        amountDue: null,
        loaded: false,
        serialNo: null,
        payment: true,
        payBils: false,
        showBtn: false,
        icons: {

            mdiClose
        },
        cardName: null,
        nextDueDate: null,
        authorizationsLength: 0,
        pendingTransaction: null,
        expiry: null,
        transactionsArray: [],
        items: [
            { text: 'Real-Time', icon: 'mdi-bell' },
            { text: 'Audience', icon: 'mdi-account' },
            { text: 'Conversions', icon: 'mdi-flag' },
            { text: 'Audience', icon: 'mdi-account' },
            { text: 'Conversions', icon: 'mdi-flag' },
        ],
        cardDetails: null,
        loaded: false,
        selectedLanguage: 'en'
    }),
    created() 
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        store.dispatch('loader/show');
        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        };

        window.scrollTo(0, 0);

        getRecentActivity.getStatementHistory().then(histpoyResp =>
        {
            this.items = histpoyResp.dateArray;
            this.getRecentActivity(false, histpoyResp.stmtDateTime);
        });

        this.cardDetails = store.get('card/details');
        this.cardName = this.cardDetails?.cardName;
        this.expiry = this.cardDetails?.expiry
        this.amountDue = this.cardDetails?.amountDue;
        this.serialNo = this.cardDetails?.serialNo;
        this.pendingTransaction = store.get('card/pendingTransaction');

        if (this.pendingTransaction?.data?.authorizationsLength)
        {
            this.authorizationsLength = this.pendingTransaction.data.authorizationsLength;
        }

        this.loaded = true;
        store.set('manageCard/details', null);
    },
    methods: {
        getArabicDate(dateTime, lang, year)
        {
            return arabicDate.get(dateTime, lang, year)
        },
        getFaqs(name)
        {
            return FAQs.getFaqs(this.selectedLanguage, name)
        },
        getRecentActivity(grouping, dateTime)
        {
            getRecentActivity.getRecentActivity(grouping, dateTime, true).then(response =>
            {
                if (response)
                {
                    groupTransactions.group(response).then(groupedTrans =>
                    {
                        this.transactionsArray = groupedTrans;
                    })

                }
                this.loaded = true;
            });
        },
        manageCards()
        {
            let selectedAccount = store.get('cardStore/selectedAccount');
            store.set('manageCard/details', selectedAccount);
            router.push('/manage-card-details')
            window.scrollTo(0, 0);
        },
        goNotification()
        {
            router.push('/alert-notifications')
            window.scrollTo(0, 0);
        },
        goToSupp()
        {
            router.push('/apply-supplementary')
            window.scrollTo(0, 0);
        },
        goStatements()
        {
            router.push('/view-transaction')
            window.scrollTo(0, 0);
        },
        goToTransaction()
        {
            router.push('/view-transaction')
            window.scrollTo(0, 0);
        },
        goToPendingTransaction()
        {
            router.push('/pending-transactions')
            window.scrollTo(0, 0);
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.v-application.theme--light .v-btn--is-elevated {
    /* box-shadow: 0 4px 8px -4px rgb(94 86 105 / 42%) !important; */
    box-shadow: none;
}

.v-application.theme--light .v-btn--is-elevated {
    box-shadow: none !important;
}

.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.en-additionalcard {
    border: 1px solid #016FD0;
    font-size: 11px;
    border-radius: 5px;
}

.en-balance-text {
    font-size: 9px;
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.image {
    height: 13px;
    margin-top: 2px;
}

.top-space {
    margin-top: 13px;
}

.union {
    height: 17px;
    top: -16px;
}

.manage-Card {
    height: 37px;
}

.notification {
    height: 37px;
}

.statement {
    height: 37px;
}

@media (max-width: 1250px) {

    // auth bg and tree hide in sm screen
    .top-space {
        margin-top: 0px;
        margin-left: 12px;
        margin-right: 12px;
    }
}

@media (max-width: 500px) {

    // auth bg and tree hide in sm screen
    .top-space {
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media (max-width: 1280px) {

    // auth bg and tree hide in sm screen
    .card-paymentdue {
        padding: 19px 7px;
    }
}

.pending-img {
    background: rgba(255, 192, 67, 0.2);
    width: 38px;
    height: 37px;
    padding-top: 26px;
    border-radius: 79px;
    margin: 0 auto;
    margin-left: 12px;
}

.card-actions {
    background: #008EF0;
    width: 38px;
    height: 37px;
    padding-top: 26px;
    border-radius: 79px;
    margin: 0 auto;
}

.payment-due {
    width: 38px;
}

.cross {
    width: 16px;
}

.alert {
    height: 37px;
}
</style>
